import React from 'react';

import { BaseWidget, ComponentWithCmsProps, Widget } from '../../types';

import decode from '../../lib/decode/decode';
import Icon from '../icon/Icon.component';
import SharedComponent from '../SharedComponent';
import Text from '../text/Text.component';

import './iconContent.scss';

export type IconContentWidget = BaseWidget<'IconContent', never> & {
  icon: string;
  title: string;
  content: Widget[];
  roleAttr?: string;
  ariaLabelText?: string;
};

type IconContentProps = ComponentWithCmsProps<IconContentWidget, {}>;

export default function IconContent(props: IconContentProps) {
  const attrs = {};

  if (props.ariaLabelText) {
    Object.assign(attrs, { 'aria-label': decode(props.ariaLabelText) });
  }

  if (props.roleAttr) {
    Object.assign(attrs, { role: props.roleAttr });
  }

  return (
    <div className="supernova-text-content" {...attrs}>
      <Icon {...{ icon: props.icon, classes: 'icon-content' }} />
      <Text classes="supernova-text-content-title fontSizeH3" properties={{ size: 'h3' }} value={props.title} />
      <div className="supernova-text-content-txt">{SharedComponent.Map(props.content)}</div>
    </div>
  );
}
